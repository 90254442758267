




















































import {Vue, Prop, Component} from 'vue-property-decorator'

import type {PreviousSearch} from '@/transforms'

@Component({
    name: 'PreviousConnectionSearches',
})
export default class PreviousConnectionSearches extends Vue {
    @Prop({type: Array, required: true})
    private readonly searches!: PreviousSearch[]
}
