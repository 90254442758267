
import type {FunctionalComponentOptions, VNode, VueConstructor} from 'vue'

export type DbiDynamicInputListOptions = {
    component: VueConstructor
    id: string
    maxItems: number
}

type Props = {values: Array<unknown>; options: DbiDynamicInputListOptions}

const component: FunctionalComponentOptions<Props> = {
    name: 'DbiDynamicInputList',
    functional: true,
    model: {
        prop: 'values',
        event: 'change',
    },
    props: {
        options: {type: Object, required: true},
        values: {type: Array, required: true},
    },
    render(createElement, context): VNode {
        const {
            values,
            options: {id, component, maxItems},
        } = context.props
        const elements = values.map((value, index) => {
            return createElement(component, {
                key: `${id}-${index}`,
                props: {value},
                on: {
                    input: (value: Props['values'][number]) => {
                        values[index] = value
                    },
                    delete: () => {
                        values.splice(index, 1)
                    },
                },
            })
        })

        if (values.length < maxItems) {
            elements.push(
                createElement(component, {
                    key: `${id}-${values.length}`,
                    props: {value: null},
                    on: {
                        input: (value: Props['values'][number]) => {
                            values.push(value)
                        },
                    },
                }),
            )
        }

        return createElement('div', context, elements)
    },
}

export default component
