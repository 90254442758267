































import {Component, Model, Vue} from 'vue-property-decorator'

import DbiSuggestInput from '@/components/form/DbiSuggestInput.vue'

import {Subscription} from '@/decorators'
import {transformHafasLocationResponse} from '@/transforms'

import type {Stopover, SearchLocation} from '@/transforms'

import {createNewStopover} from '@/helper'

const TIME_OPTIONS = [5, 10, 15, 30, 45, 60, 90, 120, 180, 240]

@Component({
    name: 'DbiStopoverInput',
    components: {
        DbiSuggestInput,
    },
})
export default class DbiStopoverInput extends Vue {
    @Model('input')
    private readonly value!: Stopover | null

    stationSuggestions: Dbi.Station[] = []
    timeOptions = TIME_OPTIONS

    get innerValue(): Stopover {
        return this.value || createNewStopover()
    }

    get station(): SearchLocation | null {
        return this.innerValue.station
    }

    set station(station: SearchLocation | null) {
        this.innerValue.station = station
        this.$emit('input', this.innerValue)
    }

    get time(): number | null {
        return this.innerValue.time
    }

    set time(time: number | null) {
        this.innerValue.time = time
        this.$emit('input', this.innerValue)
    }

    onStationInput(input: string): void {
        this.$queue.send('HafasLocation', {
            input,
            type: 'ALL',
        })
    }

    @Subscription('HafasLocation')
    gotResponse(response: DbiApi.HafasLocationResponse): void {
        this.stationSuggestions = transformHafasLocationResponse(response)
    }
}
