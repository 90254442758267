













import {Component, Vue, Prop} from 'vue-property-decorator'

import DbiTrainInformation from '@common/components/misc/DbiTrainInformation.vue'

@Component({
    name: 'ConnectionDetailInformation',
    components: {
        DbiTrainInformation,
    },
})
export default class ConnectionDetailInformation extends Vue {
    @Prop({type: Object, required: true})
    private readonly connection!: Dbi.Connection
}
