


































































import {Vue, Prop, PropSync, Component} from 'vue-property-decorator'

import DbiDynamicInputList from '@common/components/form/DbiDynamicInputList.vue'
import DbiSelect from '@common/components/form/DbiSelect.vue'
import DbiCheckbox from '@common/components/form/DbiCheckbox.vue'
import DbiLayer from '@/components/layout/DbiLayer.vue'
import DbiLayerTopBar from '@/components/layout/DbiLayerTopBar.vue'
import DbiStopoverInput from '@/components/form/DbiStopoverInput.vue'
import TransportTypeSelect from '@/components/connectionSearch/TransportTypeSelect.vue'

import {createNewSearchElaboration} from '@/helper'
import type {SearchParams} from '@/transforms'

const CHANGE_TIME_ITEMS: Array<
    SearchParams['elaboration']['changeTime'] & {
        title: string
    }
> = [
    {title: 'langsam', type: 'variable', value: 200},
    {title: 'normal', type: 'variable', value: 100},
]

;[5, 10, 15, 20, 25, 30].forEach((value) => {
    CHANGE_TIME_ITEMS.push({title: `${value} Minuten`, type: 'fixed', value})
})

@Component({
    name: 'ConnectionSearchElaboration',
    components: {
        DbiDynamicInputList,
        DbiStopoverInput,
        DbiLayer,
        DbiLayerTopBar,
        DbiSelect,
        DbiCheckbox,
        TransportTypeSelect,
    },
})
export default class ConnectionSearchElaboration extends Vue {
    @Prop({type: Object, required: true})
    private readonly locations!: SearchParams['locations']

    @PropSync('open', {type: Boolean, required: true})
    private elaborationOpen!: boolean

    @PropSync('elaboration', {type: Object, default: null})
    private elaborationSettings!: SearchParams['elaboration']

    settings = {} as SearchParams['elaboration']
    dbiStopoverInput = DbiStopoverInput
    changeTimeItems = CHANGE_TIME_ITEMS
    buttons = {
        left: {
            icon: 'arrow-left',
            title: 'zurück',
            onClick: this.close.bind(this),
        },
        right: {
            icon: 'reset',
            title: 'zurücksetzen',
            type: 'reset',
            onClick: this.reset.bind(this),
        },
    }

    get locationsArray(): string[] {
        const arr = [this.locations.origin?.name || '']

        this.settings.via.forEach(({station}) => {
            arr.push(station?.name || '')
        })

        arr.push(this.locations.destination?.name || '')
        return arr
    }

    created(): void {
        this.settings = createNewSearchElaboration(this.elaborationSettings)
    }

    saveSettings(): void {
        this.elaborationSettings = this.settings
        this.close()
    }

    close(): void {
        this.elaborationOpen = false
    }

    reset(): void {
        this.settings = createNewSearchElaboration()
    }
}
