
import {FunctionalComponentOptions, VNode} from 'vue'

import TransportType from '@common/components/misc/TransportType.vue'
import DbiDuration from '@common/components/misc/DbiDuration.vue'

const component: FunctionalComponentOptions<{
    section: Dbi.TripSectionTransport
}> = {
    name: 'ConnectionDetailDuration',
    functional: true,
    props: {
        section: {
            type: Object,
            required: true,
        },
    },
    render(createElement, context) {
        const {
            section: {product, duration, stops},
        } = context.props

        return (
            <div class="stop-wrapper">
                <div class="section-times">
                    {createElement(DbiDuration, {
                        props: {
                            duration,
                            twoLines: true,
                        },
                    })}
                </div>

                {stops && (
                    <div class="section-information">
                        {createElement(TransportType, {
                            props: {product, showIcon: true},
                        })}
                        <span class="final-destination">nach {product.direction}</span>
                    </div>
                )}
            </div>
        ) as VNode
    },
}
export default component
