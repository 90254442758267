
import {FunctionalComponentOptions, VNode} from 'vue'

import ConnectionOverview from '@/components/connectionSearch/ConnectionOverview.vue'

import {isSameDay} from '@common/lib'
import {displayDate} from '@common/filters'

const component: FunctionalComponentOptions<{
    connections: Dbi.Connection[]
    date: Date
}> = {
    name: 'ConnectionSearchResults',
    functional: true,
    props: {
        connections: {
            type: Array,
            required: true,
        },
        date: {
            type: Date,
            required: true,
        },
    },
    render(createElement, context) {
        const children: VNode[] = []
        const showWeekday = true

        function addDateEle(date: Date): void {
            children.push(createElement('li', {key: date.valueOf(), class: 'date'}, displayDate(date, {showWeekday})))
        }

        const {connections, date} = context.props

        let curDate = connections[0].departure.soll

        if (!isSameDay(curDate, date)) {
            addDateEle(curDate)
        }

        connections.forEach((connection) => {
            const {soll} = connection.departure
            if (!isSameDay(soll, curDate)) {
                curDate = soll
                addDateEle(curDate)
            }

            children.push(
                createElement(ConnectionOverview, {
                    key: connection.tripId,
                    props: {connection},
                    on: {click: context.listeners.connectionClicked},
                }),
            )
        })

        return createElement('ul', {class: 'connections-list'}, children)
    },
}
export default component
