




















import {Component, Prop, Mixins} from 'vue-property-decorator'

import {InputMixin, InputMixinOptions} from './inputMixin'

@Component({
    name: 'DbiRadio',
})
export default class DbiRadio<ValueType> extends Mixins<InputMixin<InputMixinOptions, unknown>>(InputMixin) {
    @Prop({type: String, required: true})
    readonly item!: string

    get checked() {
        return this.value === this.item
    }

    sendInput({target: {value}}: any) {
        this.$emit('change', value)
        this.$emit('input', value)
    }
}
