var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"previous-searches"},[_c('span',{staticClass:"heading"},[_vm._t("default")],2),_vm._v(" "),_c('table',[_vm._m(0),_vm._v(" "),_c('tbody',_vm._l((_vm.searches),function(search,index){return _c('tr',{key:index,staticClass:"last-connection",on:{"click":function($event){return _vm.$emit('repeat', search)}}},[_c('td',{staticClass:"stations"},[_c('span',{staticClass:"search-origin"},[_vm._v(_vm._s(search.locations.origin.name))]),_vm._v(" "),_c('span',{staticClass:"search-destination"},[_vm._v(_vm._s(search.locations.destination.name))])]),_vm._v(" "),_c('td',{staticClass:"stops"},[(!search.elaboration.via.length)?[_vm._v("-")]:[_vm._v("\n                        "+_vm._s(search.elaboration.via.map(function (ref) {
	var station = ref.station;

	return station.name;
}).join(', '))+"\n                    ")]],2),_vm._v(" "),(search.elaboration.via.length)?_c('td',{staticClass:"transports"},_vm._l((search.elaboration.via),function(change,index){return _c('div',{key:index},[(change.products.selection === 'all')?_c('span',[_vm._v("Alle")]):(change.products.selection === 'shortDistance')?_c('span',[_vm._v("Nur Nahverkehr")]):[_vm._v("\n                            "+_vm._s(change.products.custom.map(function (ref) {
	var title = ref.title;

	return title;
}).join(', '))+"\n                        ")]],2)}),0):_c('td',{staticClass:"transports"},[(search.elaboration.products.selection === 'all')?_c('span',[_vm._v("Alle")]):(search.elaboration.products.selection === 'shortDistance')?_c('span',[_vm._v("Nur Nahverkehr")]):[_vm._v("\n                        "+_vm._s(search.elaboration.products.custom.map(function (ref) {
	var title = ref.title;

	return title;
}).join(', '))+"\n                    ")]],2)])}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"stations"},[_vm._v("Start- und Zielbahnhof")]),_vm._v(" "),_c('th',{staticClass:"stops"},[_vm._v("Zwischenhalte")]),_vm._v(" "),_c('th',{staticClass:"products"},[_vm._v("Verkehrsmittel")])])])}]

export { render, staticRenderFns }