















import {Vue, Component, Prop} from 'vue-property-decorator'

import SvgSprite from '@common/components/baseComponents/SvgSprite.vue'
import TransportType from '@common/components/misc/TransportType.vue'

import {getTrainInfoIcon} from '@common/helper'

// TODO: clean-up / combine together with DbiMessages

@Component({
    name: 'DbiTrainInformation',
    components: {
        SvgSprite,
        TransportType,
    },
})
export default class DbiTrainInformation extends Vue {
    @Prop({type: Array, required: true})
    private readonly messages!: Dbi.HimMessage[]

    @Prop({type: Object, required: false})
    private readonly product!: Dbi.TripSectionTransport['product']

    transformIcon(message: Dbi.HimMessage): string {
        return getTrainInfoIcon(message)
    }
}
