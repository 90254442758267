
import {FunctionalComponentOptions} from 'vue'

import TrainTime from '@common/components/misc/TrainTime.vue'
import TrainTrack from '@common/components/misc/TrainTrack.vue'

const component: FunctionalComponentOptions<{
    station: string
    lastConnection: boolean
    time: Dbi.TimeEntry
    track: Dbi.Track
    isStopover: boolean
}> = {
    name: 'ConnectionDetailStop',
    functional: true,
    props: {
        station: {
            type: String,
            required: true,
        },
        lastConnection: {
            type: Boolean,
            default: false,
        },
        time: {
            type: Object,
            required: true,
        },
        track: {
            type: Object,
            required: false,
        },
        isStopover: {
            type: Boolean,
            default: false,
        },
    },
    render(createElement, context) {
        const {station, lastConnection, time, track, isStopover} = context.props
        const icon = lastConnection ? 'destination-filled' : isStopover ? 'stopover' : 'stop'

        const childElements = [
            createElement(TrainTime, {
                staticClass: 'section-times',
                props: {
                    time: time,
                    tag: 'div',
                    showDiffString: false,
                    twoRows: true,
                },
            }),
            createElement('svg-sprite', {
                props: {icon},
                staticClass: 'stop-icon',
            }),
            createElement('div', {staticClass: 'station'}, station),
        ]

        if (track) {
            childElements.push(
                createElement(TrainTrack, {
                    staticClass: 'tracks',
                    props: {
                        track,
                        onlyChanges: true,
                        tag: 'div',
                        showText: true,
                    },
                }),
            )
        }

        return createElement(
            'div',
            {
                staticClass: 'stop-wrapper',
                class: {
                    stopover: isStopover,
                },
            },
            childElements,
        )
    },
}
export default component
