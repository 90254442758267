
import {FunctionalComponentOptions, VNode} from 'vue'

import DbiDuration from '@common/components/misc/DbiDuration.vue'

const component: FunctionalComponentOptions<{
    section: Dbi.TripSection
}> = {
    name: 'ConnectionDetailChangeover',
    functional: true,
    props: {
        section: {
            type: Object,
            required: true,
        },
    },
    render(createElement, context) {
        const {section} = context.props

        if (section.type === 'walk') {
            return (
                <div class="stop-wrapper">
                    <div class="walking-duration">
                        {createElement(DbiDuration, {
                            props: {duration: section.walk.duration},
                        })}
                    </div>

                    <div class="changeover">
                        <div class="walking-distance">
                            <svg-sprite icon="walking" class="walking-icon" />
                            <span>{section.walk.distance} m</span>
                        </div>
                    </div>

                    <div class="line"></div>
                </div>
            ) as VNode
        }

        const {reachable, changeoverTime} = section

        return (
            <div class="stop-wrapper">
                <div class="section-times"></div>

                <div class={`changeover ${!reachable && 'not-reachable'}`}>
                    <div class="changeover-time">
                        <svg-sprite icon="changeover" class="changeover-icon" />
                        {createElement(DbiDuration, {
                            props: {duration: changeoverTime},
                        })}
                    </div>

                    {section.walk && (
                        <div class="changeover-walk">
                            <svg-sprite icon="walking" class="walking-icon" />
                            <span>
                                {section.walk.duration} min ({section.walk.distance} m)
                            </span>
                        </div>
                    )}
                </div>

                <div class="line"></div>
            </div>
        ) as VNode
    },
}
export default component
