


















































import {Vue, Model, Component} from 'vue-property-decorator'

import DbiCalender from '@common/components/form/DbiCalender.vue'
import TimeSelect, {TimeSelectValue} from './TimeSelect.vue'
import DbiLayer from '@/components/layout/DbiLayer.vue'
import DbiLayerTopBar from '@/components/layout/DbiLayerTopBar.vue'

import type {SearchParams} from '@/transforms'
import {timePad} from '@common/lib'

type Tabs = 'date' | 'time'

@Component({
    name: 'DateTimeSelect',
    components: {
        DbiLayer,
        DbiLayerTopBar,
        DbiCalender,
        TimeSelect,
    },
    filters: {
        displayType(type: SearchParams['time']['type']): 'Ab' | 'An' {
            return type === 'departure' ? 'Ab' : 'An'
        },
    },
})
export default class DateTimeSelect extends Vue {
    @Model('change')
    private readonly value!: SearchParams['time']

    activeTab: Tabs | null = null
    innerValue:
        | ({
              date: Date
          } & TimeSelectValue)
        | null = null

    buttons = {
        left: {
            icon: 'arrow-left',
            title: 'zurück',
            onClick: this.close.bind(this),
        },
    }

    openTab(tab: Tabs): void {
        if (!this.activeTab) {
            const {date, type} = this.value
            this.innerValue = {
                date,
                type,
                hours: timePad(date.getHours()),
                minutes: timePad(date.getMinutes()),
            }
        }
        this.activeTab = tab
    }

    close(): void {
        this.activeTab = null
        this.innerValue = null
    }

    onDateSelected(): void {
        this.activeTab = 'time'
    }

    onSubmit(): void {
        if (this.innerValue) {
            const {date, type, hours, minutes} = this.innerValue
            date.setHours(parseInt(hours))
            date.setMinutes(parseInt(minutes))
            this.$emit('change', {date, type})
        }
        this.close()
    }
}
