














import {Component, Vue, Prop} from 'vue-property-decorator'

import DbiMessages from '@common/components/misc/DbiMessages.vue'

@Component({
    name: 'ConnectionDetailMessages',
    components: {
        DbiMessages,
    },
})
export default class ConnectionDetailMessages extends Vue {
    @Prop({type: Object, required: true})
    private readonly connection!: Dbi.Connection
}
