

















import {Component, Prop, Mixins} from 'vue-property-decorator'

import {InputMixin, InputMixinOptions} from './inputMixin'

export type DbiSelectOptions = InputMixinOptions & {
    itemTitle?: string
    itemValue?: string
    defaultText?: string
    returnItemAsValue?: boolean
}

const OPTION_DEFAULTS = {
    itemTitle: 'title',
    itemValue: 'value',
    returnItemAsValue: false,
}

// FIXME:
// since "Record<string, unknown>" is ValueType find a way to use that
@Component({
    name: 'DbiSelect',
})
export default class DbiSelect<ValueType extends Record<string, unknown>> extends Mixins<
    InputMixin<DbiSelectOptions, Record<string, unknown>>
>(InputMixin) {
    @Prop({type: Array, required: true})
    private readonly items!: Array<ValueType>

    get innerModel(): ValueType | null {
        const {
            value,
            itemValue,
            innerOptions: {returnItemAsValue},
        } = this

        if (value === undefined || value === null) {
            return null
        }
        return (returnItemAsValue ? value[itemValue as keyof typeof value] : value) as ValueType
    }

    set innerModel(value: ValueType | null) {
        if (value && this.innerOptions.returnItemAsValue) {
            const {items, itemValue} = this
            value = items.find((item) => item[itemValue] === value) as ValueType
        }

        this.$emit('change', value)
        this.$emit('input', value)
    }

    get innerOptions(): DbiSelectOptions {
        return Object.assign({}, OPTION_DEFAULTS, this.options)
    }

    get itemValue(): keyof ValueType {
        return this.innerOptions.itemValue as keyof ValueType
    }

    get itemTitle(): string {
        return this.innerOptions.itemTitle as string
    }
}
