
import type {FunctionalComponentOptions, VNode} from 'vue'
import {mergeData} from 'vue-functional-data-merge'

import {timePad} from '@common/lib'

const component: FunctionalComponentOptions<{
    duration: number
    twoLines: boolean
    noUnits: boolean
}> = {
    name: 'DbiDuration',
    functional: true,
    props: {
        duration: {
            type: Number,
            required: true,
        },
        twoLines: {
            type: Boolean,
            default: false,
        },
        noUnits: {
            type: Boolean,
            default: false,
        },
    },
    render(createElement, {data, props}) {
        const {duration, twoLines, noUnits} = props

        const hours = Math.floor(duration / 60)
        let minutes: number | string = hours < 0 ? duration : duration - hours * 60

        if (hours > 0 || noUnits) {
            minutes = timePad(minutes)
        }

        function getMarkup(value: number | string, unit: string): VNode {
            return createElement('span', `${value} ${unit}`)
        }

        function renderContent(): string | VNode[] {
            if (noUnits) {
                return `Dauer: ${hours}:${minutes}`
            }
            else {
                const children = [getMarkup(minutes, 'min')]
                if (hours > 0) {
                    children.unshift(getMarkup(hours, 'h'))
                }
                return children
            }
        }

        const componentData = {
            staticClass: 'duration',
            class: {
                'two-lines': twoLines,
            },
        }

        return createElement('div', mergeData(data, componentData), renderContent())
    },
}
export default component
