
import {FunctionalComponentOptions, VNode} from 'vue'

import ConnectionDetailStop from '@/components/connectionSearch/ConnectionDetailStop.vue'

const component: FunctionalComponentOptions<{
    stops: Array<Dbi.TrainStop>
}> = {
    name: 'ConnectionDetailAccordion',
    functional: true,
    props: {
        stops: {
            type: Array,
            required: true,
        },
    },
    render(createElement, context) {
        const sectionStops = context.props.stops

        const trimmedStops = sectionStops.slice(1, sectionStops.length - 1)
        const hasStopovers = trimmedStops.length
        const stopText = !hasStopovers ? '1 Halt' : `${sectionStops.length - 1} Halte`

        const stops = trimmedStops.map(({station, departure, arrival, track}) => {
            return createElement(ConnectionDetailStop, {
                props: {
                    station: station.name,
                    time: departure || arrival,
                    isStopover: true,
                    track,
                },
            })
        })

        return (
            <div>
                <div class="accordion">
                    <details>
                        <summary>
                            <div class="summary-text">
                                <span>{stopText}</span>
                                {hasStopovers !== 0 &&
                                    createElement('svg-sprite', {
                                        props: {icon: 'chevron-down'},
                                        class: 'toggle-icon',
                                    })}
                            </div>
                        </summary>
                        {stops}
                    </details>
                </div>
            </div>
        ) as VNode
    },
}
export default component
