
import {FunctionalComponentOptions, VNode} from 'vue'

import ConnectionDetailAccordion from '@/components/connectionSearch/ConnectionDetailAccordion.vue'
import ConnectionDetailStop from '@/components/connectionSearch/ConnectionDetailStop.vue'
import ConnectionDetailDuration from '@/components/connectionSearch/ConnectionDetailDuration.vue'

const component: FunctionalComponentOptions<{
    section: Dbi.TripSectionTransport
    lastConnection: boolean
    firstConnection: boolean
    connection: Dbi.Connection
}> = {
    name: 'ConnectionDetailSection',
    functional: true,
    props: {
        section: {
            type: Object,
            required: true,
        },
        lastConnection: {
            type: Boolean,
            default: false,
        },
        firstConnection: {
            type: Boolean,
            default: false,
        },
        connection: {
            type: Object,
            required: false,
        },
    },
    render(createElement, context) {
        const {section, connection, lastConnection, firstConnection} = context.props

        const arrivalTime = section.type === 'transport' ? section.arrival.time : connection.arrival

        const departureTime = section.type === 'transport' ? section.departure.time : connection.departure

        const walkingConnection = !section.stops

        function renderLine(): VNode {
            return createElement(
                'div',
                {
                    key: 'connection-line',
                    staticClass: 'line-wrapper',
                },
                [createElement('div', {staticClass: 'line-body'})],
            )
        }

        function renderContent(): VNode[] {
            if (walkingConnection) {
                if (firstConnection) {
                    return [
                        createElement(ConnectionDetailStop, {
                            props: {
                                station: section.origin,
                                time: departureTime,
                            },
                        }),
                    ]
                }

                return [
                    createElement(ConnectionDetailStop, {
                        props: {
                            station: section.destination,
                            lastConnection,
                            time: arrivalTime,
                        },
                    }),
                ]
            }

            return [
                createElement(ConnectionDetailStop, {
                    props: {
                        station: section.origin,
                        track: section.departure.track,
                        time: departureTime,
                    },
                }),
                createElement('div', {staticClass: 'section-info'}, [
                    renderLine(),
                    createElement(ConnectionDetailDuration, {
                        props: {
                            section: section,
                        },
                    }),
                    createElement(ConnectionDetailAccordion, {
                        props: {stops: section.stops},
                    }),
                ]),
                createElement(ConnectionDetailStop, {
                    props: {
                        station: section.destination,
                        track: section.arrival.track,
                        lastConnection,
                        time: arrivalTime,
                    },
                }),
            ]
        }

        return createElement(
            'div',
            {
                staticClass: 'wrapper',
                class: {
                    walking: walkingConnection,
                },
            },
            renderContent(),
        )
    },
}
export default component
