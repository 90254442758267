








































import {Vue, PropSync, Component, Ref} from 'vue-property-decorator'

import type {SearchParams} from '@/transforms'
import {clamp, timePad} from '@common/lib'
import {TIME_TYPES} from '@common/const'

export type TimeSelectValue = {
    hours: string
    minutes: string
    type: SearchParams['time']['type']
}

@Component({
    name: 'DbiTimeSelect',
})
export default class DbiTimeSelect extends Vue {
    @PropSync('value', {type: Object, required: true})
    private innerValue!: TimeSelectValue

    @Ref()
    private readonly typesList!: HTMLUListElement

    @Ref()
    private readonly hoursInput!: HTMLInputElement

    @Ref()
    private readonly minutesInput!: HTMLInputElement

    timeTypes = TIME_TYPES

    lineHeight = 0

    get translation(): number {
        const idx = this.timeTypes.findIndex(({type}) => type === this.innerValue.type)
        return idx * this.lineHeight
    }

    mounted(): void {
        this.lineHeight = parseInt(getComputedStyle(this.typesList).lineHeight)
    }

    onFocus(event: FocusEvent): void {
        ;(event.target as HTMLInputElement).value = ''
    }

    onHoursBlur(): void {
        const {hoursInput, innerValue} = this
        if (hoursInput.value) {
            innerValue.hours = hoursInput.value
        }
        else {
            hoursInput.value = innerValue.hours
        }
    }

    onMinutesBlur(): void {
        const {minutesInput, innerValue} = this
        if (minutesInput.value) {
            innerValue.minutes = minutesInput.value
        }
        else {
            minutesInput.value = innerValue.minutes
        }
    }

    onHoursInput(): void {
        const {hoursInput, minutesInput} = this
        const value = clamp(hoursInput.valueAsNumber, 0, 23)
        hoursInput.value = timePad(value)
        if (value.toString().length >= 2) {
            hoursInput.blur()
            this.$nextTick(() => {
                minutesInput.focus()
            })
        }
    }

    onMinutesInput(): void {
        const {minutesInput} = this
        const value = clamp(minutesInput.valueAsNumber, 0, 59)
        minutesInput.value = timePad(value)
        if (value.toString().length >= 2) {
            minutesInput.blur()
        }
    }
}
