




























import {Component, Prop, Model, Vue} from 'vue-property-decorator'

import DbiRadio from '@common/components/form/DbiRadio.vue'
import TransportSticker from '@common/components/misc/TransportSticker.vue'

import {TRANSPORT_TYPES} from '@common/const'
import type {TransportType} from '@common/const'
import type {SearchTransportType} from '@/transforms'

@Component({
    name: 'TransportTypeSelect',
    components: {
        DbiRadio,
        TransportSticker,
    },
})
export default class TransportTypeSelect extends Vue {
    @Model('change', {type: Object, required: true})
    private readonly value!: SearchTransportType

    @Prop({type: String, required: true})
    private readonly id!: string

    transportTypes = TRANSPORT_TYPES

    isChecked(transportType: TransportType): boolean {
        return this.value.custom.includes(transportType)
    }

    toggleSelection(transportType: TransportType): void {
        const {custom} = this.value
        const idx = custom.indexOf(transportType)
        if (idx >= 0) {
            custom.splice(idx, 1)
        }
        else {
            custom.push(transportType)
        }
    }
}
