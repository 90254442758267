































import {Component, Vue, Prop} from 'vue-property-decorator'

import ConnectionDetailSection from '@/components/connectionSearch/ConnectionDetailSection.vue'
import ConnectionDetailChangeover from '@/components/connectionSearch/ConnectionDetailChangeover.vue'

@Component({
    name: 'ConnectionDetailRoute',
    components: {
        ConnectionDetailSection,
        ConnectionDetailChangeover,
    },
})
export default class ConnectionDetailRoute extends Vue {
    @Prop({type: Object, required: true})
    private readonly connection!: Dbi.Connection
}
