











































import {Vue, Model, Component} from 'vue-property-decorator'

import {isSameDay, isPastDay} from '@common/lib'

const MONTH_NAMES = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
]

@Component({
    name: 'DbiCalender',
})
export default class DbiCalender extends Vue {
    @Model('change')
    private readonly value!: Date

    isSameDay = isSameDay
    isPastDay = isPastDay

    innerDate!: Date
    weeks: Date[][] = []
    monthName!: string

    created(): void {
        const date = new Date(this.value)
        date.setDate(1)
        this.innerDate = date
        this.updateCalender()
    }

    updateCalender(): void {
        this.monthName = MONTH_NAMES[this.innerDate.getMonth()]
        this.calcWeeks()
    }

    calcWeeks(): void {
        const {weeks, innerDate} = this
        weeks.splice(0, weeks.length)
        const curMonth = innerDate.getMonth()
        const curDay = new Date(innerDate)
        const dayOfWeek = curDay.getDay() - 1
        if (dayOfWeek) {
            const offset = dayOfWeek > 0 ? dayOfWeek - 1 : 5
            curDay.setDate(-offset)
        }
        let week: Date[] = []
        while (true) {
            week.push(new Date(curDay))
            curDay.setDate(curDay.getDate() + 1)

            if (curDay.getDay() === 1) {
                weeks.push(week)
                if (curDay.getMonth() !== curMonth) {
                    break
                }
                week = []
            }
        }
    }

    setMonth(step: number): void {
        const {innerDate} = this
        innerDate.setMonth(innerDate.getMonth() + step)
        this.updateCalender()
    }
}
