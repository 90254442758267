







































import {Vue, PropSync, Component} from 'vue-property-decorator'

import DbiLayer from '@/components/layout/DbiLayer.vue'
import DbiLayerTopBar from '@/components/layout/DbiLayerTopBar.vue'
import TabNavigation, {TabNavigationLink} from '@common/components/ui/TabNavigation.vue'
import ConnectionDetailRoute from '@/components/connectionSearch/ConnectionDetailRoute.vue'
import ConnectionDetailInformation from '@/components/connectionSearch/ConnectionDetailInformation.vue'
import ConnectionDetailMessages from '@/components/connectionSearch/ConnectionDetailMessages.vue'
import DbiDuration from '@common/components/misc/DbiDuration.vue'
import NotificationIndicator from '@common/components/ui/NotificationIndicator.vue'

import TrainTime from '@common/components/misc/TrainTime.vue'

const TABS: TabNavigationLink[] = [
    {
        component: ConnectionDetailRoute,
        icon: 'route',
        title: 'Fahrtverlauf',
    },
    {
        component: ConnectionDetailInformation,
        icon: 'train-details',
        title: 'Zuginformationen',
    },
    {
        component: ConnectionDetailMessages,
        icon: 'warning',
        title: 'HIM-Meldungen',
    },
]

@Component({
    name: 'ConnectionDetail',
    components: {
        DbiLayer,
        DbiLayerTopBar,
        TabNavigation,
        TrainTime,
        DbiDuration,
        NotificationIndicator,
    },
})
export default class ConnectionDetail extends Vue {
    @PropSync('connection', {type: Object, default: null})
    private innerConnection!: Dbi.Connection | null

    tabs = TABS
    buttons = {
        left: {
            icon: 'arrow-left',
            title: 'zurück',
            onClick: this.close.bind(this),
        },
    }

    scrollElement: Element | null = null

    mounted(): void {
        this.scrollElement = this.$el
    }

    get hasSections(): boolean {
        return this.innerConnection ? this.innerConnection.sections.length > 1 : false
    }

    close(): void {
        this.innerConnection = null
    }
}
