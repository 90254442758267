

































































































import {Component, Vue, Watch} from 'vue-property-decorator'

import DbiLoader from '@common/components/ui/DbiLoader.vue'
import StationSelect from '@/components/form/StationSelect.vue'
import DateTimeSelect from '@/components/form/DateTimeSelect.vue'
import ConnectionSearchElaboration from '@/components/connectionSearch/ConnectionSearchElaboration.vue'
import ConnectionSearchResults from '@/components/connectionSearch/ConnectionSearchResults.vue'
import ConnectionDetail from '@/components/connectionSearch/ConnectionDetail.vue'
import PreviousConnectionSearches from '@/components/connectionSearch/PreviousConnectionSearches.vue'

import {Subscription} from '@/decorators'
import {SearchParams, createSearchRequest, transformConnectionResponse, PreviousSearch} from '@/transforms'
import {RootState} from '@/store/state'
import {Deferred} from '@common/lib'
import {createNewSearchElaboration, isSearchElaborationChanged} from '@/helper'

@Component({
    name: 'ConnectionSearch',
    components: {
        DbiLoader,
        StationSelect,
        DateTimeSelect,
        ConnectionSearchElaboration,
        ConnectionSearchResults,
        ConnectionDetail,
        PreviousConnectionSearches,
    },
})
export default class ConnectionSearch extends Vue {
    locations = {
        origin: null as Dbi.Station | null,
        destination: null as Dbi.Station | null,
    }

    time = {
        date: new Date(),
        type: 'departure',
    }

    elaboration = createNewSearchElaboration()

    elaborationOpen = false

    selectedConnection: Dbi.Connection | null = null

    connections: Dbi.Connection[] = []

    scrollBackward = ''
    scrollForward = ''

    lastSearches: PreviousSearch[] = []

    searchWaiting = false
    searchRunning: Deferred<void> | null = null

    get commonSearches(): RootState['connectionSearches']['station'] {
        return this.$dbiStore.state.connectionSearches.station
    }

    get canSearch(): boolean {
        const {
            locations: {origin, destination},
        } = this
        return !!(origin && destination)
    }

    get elaborationChanged(): boolean {
        return isSearchElaborationChanged(this.elaboration)
    }

    created(): void {
        this.reset()
    }

    flipLocations(): void {
        const {locations, elaboration} = this
        const tmp = locations.origin
        locations.origin = locations.destination
        locations.destination = tmp

        if (elaboration.via.length) {
            this.flipTransports()
        }
    }

    flipTransports(): void {
        const {
            elaboration,
            elaboration: {via, products: originProducts},
        } = this

        const lastTransport = via.length - 1
        const lastTransportProducts = via[lastTransport].products

        elaboration.products = lastTransportProducts

        if (via.length === 2) {
            via.reverse()
            via[0].products = via[1].products
        }
        via[lastTransport].products = originProducts
    }

    openElaboration(): void {
        this.elaborationOpen = true
    }

    fillLastSearches(): void {
        if (!this.canSearch) {
            return
        }
        const {locations, elaboration} = this
        const searchParams = {
            locations: {...locations},
            elaboration,
        } as PreviousSearch

        this.lastSearches.unshift(searchParams)
        this.lastSearches.splice(3)
    }

    repeatSearch({locations, elaboration}: PreviousSearch): void {
        this.time.date = new Date()
        this.time.type = 'departure'

        this.elaboration = createNewSearchElaboration(elaboration)

        Object.assign(this.locations, locations)
    }

    public handleBottomBarClick(): void {
        if (this.selectedConnection) {
            this.selectedConnection = null
        }
        else if (window.scrollY !== 0) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            })
        }
        else {
            this.reset()
        }
    }

    reset(): void {
        this.fillLastSearches()
        const {locations, time, connections} = this
        locations.origin = this.$dbiStore.state.homeStation
        locations.destination = null

        time.date = new Date()
        time.type = 'departure'

        this.elaboration = createNewSearchElaboration()

        connections.splice(0, connections.length)

        this.searchRunning = null
        this.searchWaiting = false
    }

    @Watch('$dbiStore.state.homeStation')
    reactToHomeStationChange(newStation: Dbi.Station, oldStation: Dbi.Station): void {
        const {
            locations,
            locations: {origin, destination},
        } = this
        if (!destination && origin?.eva === oldStation.eva) {
            locations.origin = newStation
        }
    }

    get combinedSearchParams() {
        const {locations, time, elaboration} = this
        return {locations, time, elaboration}
    }

    @Watch('combinedSearchParams', {immediate: true, deep: true})
    triggerSearch(): void {
        this.searchConnection()
    }

    async searchConnection(context?: string): Promise<void> {
        if (this.searchWaiting) {
            return
        }
        if (this.searchRunning) {
            this.searchWaiting = true
            await this.searchRunning.promise
            this.searchWaiting = false
        }
        if (!this.canSearch) {
            return
        }
        this.searchRunning = new Deferred<void>()
        const searchParams = {
            ...this.combinedSearchParams,
            context,
        } as SearchParams

        const searchRequest = createSearchRequest(searchParams)
        this.$queue.send('Connection', searchRequest)
    }

    @Subscription('Connection')
    gotConnections(response: DbiApi.ConnectionResponse): void {
        if (this.searchRunning) {
            this.searchRunning.resolve()
            this.searchRunning = null
        }
        else {
            this.$log.warn('got search result, but no search was running')
        }

        const {context, scrollBackward, scrollForward, connections} = transformConnectionResponse(response)

        if (!context) {
            this.scrollBackward = scrollBackward
            this.scrollForward = scrollForward
            this.connections = connections
        }
        else if (context === this.scrollForward) {
            this.scrollForward = scrollForward
            this.connections.push(...connections)
        }
        else if (context === this.scrollBackward) {
            this.scrollBackward = scrollBackward
            this.connections.unshift(...connections)
        }
        else {
            this.$log.warn('couldn\'t match connection search response')
        }

        this.connections.sort((a, b) => a.departure.soll.getTime() - b.departure.soll.getTime())
    }

    onConnectionClick(connection: Dbi.Connection): void {
        this.selectedConnection = connection
    }
}
