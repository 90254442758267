
import {FunctionalComponentOptions, VNode} from 'vue'

import TrainTime from '@common/components/misc/TrainTime.vue'
import TrainTrack from '@common/components/misc/TrainTrack.vue'
import DbiDuration from '@common/components/misc/DbiDuration.vue'
import NotificationIndicator from '@common/components/ui/NotificationIndicator.vue'
import TransportType from '@common/components/misc/TransportType.vue'

const component: FunctionalComponentOptions<{connection: Dbi.Connection}> = {
    name: 'ConnectionOverview',
    functional: true,
    props: {
        connection: {
            type: Object,
            required: true,
        },
    },

    render(createElement, context) {
        const {
            connection,
            connection: {departure, arrival, sections, departureTrack, arrivalTrack, alternative, duration},
        } = context.props

        function getTrack(type: 'departure' | 'arrival', track?: Dbi.Track): VNode | void {
            return track
                ? createElement(TrainTrack, {
                    class: `track ${type}`,
                    props: {
                        track,
                        tag: 'span',
                        onlyChanges: true,
                        showText: true,
                    },
                })
                : undefined
        }

        const mappedSections: VNode[] = []
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        sections.forEach(({walk, product, reachable}) => {
            if (walk) {
                mappedSections.push(
                    createElement('span', {staticClass: 'section walk'}, [
                        createElement('svg-sprite', {props: {icon: 'walking'}}),
                    ]),
                )
            }

            if (product) {
                if (!reachable) {
                    const marker = createElement(NotificationIndicator, {
                        staticClass: 'unreachable big',
                        props: {type: 'unreachable'},
                    })
                    mappedSections.push(createElement('div', {staticClass: 'unreachable-wrapper'}, [marker]))
                }

                mappedSections.push(
                    createElement(TransportType, {
                        staticClass: 'section transport',
                        props: {product, hideNumbers: true},
                    }),
                )
            }
        })

        const click = () => {
            const {click} = context.listeners
            if (typeof click === 'function') {
                click(connection)
            }
        }

        return (
            <li class="connection-overview">
                <button class="connection-wrapper" {...{on: {click}}}>
                    <div class="info-row times">
                        {createElement(TrainTime, {
                            staticClass: 'departure',
                            props: {
                                time: departure,
                                tag: 'div',
                            },
                        })}
                        {alternative &&
                            createElement('svg-sprite', {
                                staticClass: 'icon-alternative',
                                props: {icon: 'alternative'},
                            })}
                        {createElement(TrainTime, {
                            staticClass: 'arrival',
                            props: {
                                time: arrival,
                                tag: 'div',
                            },
                        })}
                    </div>

                    <div class="sections">{mappedSections}</div>

                    <div class="info-row details">
                        {getTrack('departure', departureTrack)}
                        {createElement(DbiDuration, {
                            props: {duration},
                        })}
                        {getTrack('arrival', arrivalTrack)}
                    </div>
                </button>
            </li>
        ) as VNode
    },
}
export default component
